import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import axios from 'axios';
import { backendUrl } from "../config/url";

const columns = [
  { id: "ticker", label: "Ticker" },
  { id: "fullName", label: "Full Name" },
  { id: "sharePrice", label: "Share Price ($)" },
  { id: "atmStrikePrice", label: "ATM Strike Price ($)" },
  { id: "cashSecuredPutROI", label: "Cash-Secured Put ROI (%)" },
  { id: "coveredCallROI", label: "Covered Call ROI (%)" },
];

const mockData = [
  {
    _id: "1",
    symbol: "AAPL",
    name: "Apple Inc.",
    callSharePrice: 150.0,
    callStrikePrice: 155.0,
    callStrikePrice1: 160.0,
    callStrikePrice2: 165.0,
    callROI: 5.0,
    callROI1: 6.0,
    callROI2: 7.0,
    putROI: 4.0,
    putROI1: 5.0,
    putROI2: 6.0,
    created_at: "2023-10-01T12:00:00Z",
    marketstatus: "open"
  },
  {
    _id: "2",
    symbol: "MSFT",
    name: "Microsoft Corporation",
    callSharePrice: 300.0,
    callStrikePrice: 305.0,
    callStrikePrice1: 310.0,
    callStrikePrice2: 315.0,
    callROI: 4.5,
    callROI1: 5.5,
    callROI2: 6.5,
    putROI: 3.5,
    putROI1: 4.5,
    putROI2: 5.5,
    created_at: "2023-10-01T12:05:00Z",
    marketstatus: "closed"
  },
  // Add more mock data as needed
];

const HomeGrid = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery("(min-width:600px)");
  const buttonTextsForCalls = ["ATM"];
  const [selectedButtonText, setSelectedButtonText] = useState("ATM");
  const [rows, setRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [lastUpdatedText, setLastUpdatedText] = useState("Loading...");

  const buttonSelectionChange = (id) => {
    setSelectedButtonText(buttonTextsForCalls[id]);
    updateRows(buttonTextsForCalls[id]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(backendUrl + '/api/dashboard/latest');
        const serverTime = new Date(response.data.serverTime); // Assuming serverTime is included in the response
        const data = response.data.items.map(item => ({
          id: item._id,
          ticker: item.symbol,
          fullName: item.name,
          sharePrice: item.callSharePrice,
          atmStrikePrice: item.callStrikePrice,
          atmStrikePrice1: item.callStrikePrice1,
          atmStrikePrice2: item.callStrikePrice2,
          cashSecuredPutROI: item.putROI,
          cashSecuredPutROI1: item.putROI1,
          cashSecuredPutROI2: item.putROI2,
          coveredCallROI: item.callROI,
          coveredCallROI1: item.callROI1,
          coveredCallROI2: item.callROI2,
          created_at: item.created_at,
          marketstatus: item.marketstatus
        }));
        setOriginalData(data);
        setRows(data);
        updateLastUpdatedText(data, serverTime, response.data.marketstatus);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Use mock data instead of making an API call
  //       const serverTime = new Date(); // Use current time as server time
  //       const data = mockData.map(item => ({
  //         id: item._id,
  //         ticker: item.symbol,
  //         fullName: item.name,
  //         sharePrice: item.callSharePrice,
  //         atmStrikePrice: item.callStrikePrice,
  //         atmStrikePrice1: item.callStrikePrice1,
  //         atmStrikePrice2: item.callStrikePrice2,
  //         cashSecuredPutROI: item.callROI,
  //         cashSecuredPutROI1: item.callROI1,
  //         cashSecuredPutROI2: item.callROI2,
  //         coveredCallROI: item.putROI,
  //         coveredCallROI1: item.putROI1,
  //         coveredCallROI2: item.putROI2,
  //         created_at: item.created_at,
  //         marketstatus: item.marketstatus
  //       }));
  //       setOriginalData(data);
  //       setRows(data);
  //       updateLastUpdatedText(data, serverTime);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const updateRows = (selectedButton) => {
    const updatedRows = originalData.map(item => {
      let atmStrikePrice, cashSecuredPutROI, coveredCallROI;
      if (selectedButton === "ATM") {
        atmStrikePrice = item.atmStrikePrice;
        cashSecuredPutROI = item.cashSecuredPutROI;
        coveredCallROI = item.coveredCallROI;
      } else if (selectedButton === "5% OTM") {
        atmStrikePrice = item.atmStrikePrice1;
        cashSecuredPutROI = item.cashSecuredPutROI1;
        coveredCallROI = item.coveredCallROI1;
      } else if (selectedButton === "10% OTM") {
        atmStrikePrice = item.atmStrikePrice2;
        cashSecuredPutROI = item.cashSecuredPutROI2;
        coveredCallROI = item.coveredCallROI2;
      }
      return {
        ...item,
        atmStrikePrice,
        cashSecuredPutROI,
        coveredCallROI,
      };
    });
    setRows(updatedRows);
  };

  const updateLastUpdatedText = (data, serverTime, status) => {
    if (data.length > 0) {
      const lastItem = data[data.length - 1];
      const lastUpdatedTime = new Date(lastItem.created_at);
      const timeDifference = serverTime - lastUpdatedTime;

      // Calculate minutes, hours, and days
      const totalMinutes = Math.floor(timeDifference / (1000 * 60));
      const days = Math.floor(totalMinutes / (60 * 24));
      const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
      const minutes = totalMinutes % 60;

      // Generate the formatted string
      let formattedTime;
      if (days > 0) {
        formattedTime = `${days}d ${hours}h ${minutes}m`;
      } else if (hours > 0) {
        formattedTime = `${hours}h ${minutes}m`;
      } else {
        formattedTime = `${minutes} minutes`;
      }

      // Update the text
      console.log(lastItem);
      setLastUpdatedText(`Last Updated: ${formattedTime} ago (markets ${status})`);
    }
  };

  return (
    <section>
      <Box
        sx={{
          "& .MuiButton-root": {
            backgroundColor: "transparent",
            color: "white",
            border: `1px solid ${theme.palette.text.third}`,
            paddingY: 1,
            paddingX: isSmScreen ? 2 : 1,
          },
          "& .selected": {
            backgroundColor: `${theme.palette.text.third} !important`,
          },
        }}
        gap={isSmScreen ? 2 : 0.4}
        display="flex"
        justifyContent="center"
      >
        {buttonTextsForCalls.map((text, index) => (
          <Button
            key={index}
            className={selectedButtonText === text ? "selected" : ""}
            onClick={() => buttonSelectionChange(index)}
          >
            {text}
          </Button>
        ))}
      </Box>

      <fieldset
        style={{
          width: "70%",
          margin: "auto",
          borderColor: theme.palette.text.third,
          borderRadius: 5,
          marginTop: "3vh",
          marginBottom: "80px",
        }}
      >
        <legend>{`Top Tickers`}</legend>
          <Typography variant="h8" color="white" textAlign="left" mt={3}>
            {lastUpdatedText}
          </Typography>
        <Box
          textAlign="center"
          mt={2}
          sx={{
            width: "80vw",
            color: "white",
            "@media (min-width: 600px)": {
              width: "auto", // Set to auto for screens wider than 600px
            },
          }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <React.Fragment>
                    <TableRow
                      key={row.id}
                      sx={{
                        borderTop: `none`,
                        "&:last-child td, &:last-child th": { borderTop: "none" },
                        '@media (max-width: 600px)': {
                            display: 'flex'
                          },
                      }}
                    >
                      <TableCell
                        component="td"
                        scope="row"
                        align="left"
                        sx={{
                          borderRight: `none`,
                          color: theme.palette.text.third,
                          fontWeight: 600,
                          fontSize: '16px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          '@media (max-width: 600px)': {
                            width: '200px',
                            display: 'block'
                          },
                        }}
                      >
                        {row.fullName} 
                      </TableCell>
                      <TableCell 
                        align="center" 
                        sx={{ 
                          color: theme.palette.text.third, 
                          fontWeight: 600, 
                          fontSize: '16px',
                          '@media (max-width: 600px)': {
                            display: "block",
                            // width: "50%"
                          },
                          }}>
                        ${row.sharePrice}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key={`${row.id}-extra`}
                      sx={{
                        borderTop: `none`,
                        "&:last-child td, &:last-child th": { borderTop: "none" },
                        '@media (max-width: 600px)': {
                            display: 'block',
                          },
                      }}
                    >
                      <TableCell
                        component="td"
                        scope="row"
                        align="left"
                        sx={{
                          borderRight: `none`,
                          color: theme.palette.text.third,
                        }}
                      >
                        {row.ticker}
                      </TableCell>
                      <TableCell align="center" sx={{ color: theme.palette.text.third}}>
                        ${row.atmStrikePrice} Strike
                      </TableCell>
                      <TableCell align="center" sx={{ color: theme.palette.text.third }}>
                        Puts: {row.cashSecuredPutROI}%
                      </TableCell>
                      <TableCell align="center" sx={{ color: theme.palette.text.third }}>
                        Calls: {row.coveredCallROI}%
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </fieldset>
    </section>
  );
};

export default HomeGrid;
